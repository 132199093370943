import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { api } from "boot/axios";
import {useAuthStore} from "stores/auth";
import {ILoginResponse} from "src/types/auth/ILoginResponse";
import router from "src/router";
import {createPinia} from "pinia";
import {useAppLink} from "src/composable/general/useAppLink";
import {RouteNamesEnum} from "src/utils/enums/general/RouteNamesEnum";

export class AxiosInterceptorsService {
  public refreshTokenPromise: null | Promise<boolean> = null;
  async appResponseErrorInterceptor(axiosError: AxiosError) {
    const routerInstance = (await router({store: createPinia()}));
    if (axiosError.code === "ERR_NETWORK") {
      const { maintenanceLink } = useAppLink();
      if (routerInstance.currentRoute.value.name !== RouteNamesEnum.MAINTENANCE) {
        await routerInstance.push(maintenanceLink());
      }
      return Promise.reject(axiosError);
    }
    const originalRequest = axiosError.config;
    if (!originalRequest) {
      return Promise.reject(axiosError);
    }
    if (axiosError.response?.status === 403 || axiosError.response?.status === 401) {
      if (!this.refreshTokenPromise) {
        this.refreshTokenPromise = this.refreshToken().then((value) => {
          this.refreshTokenPromise = null;
          return value;
        });
      }

      return this.refreshTokenPromise.then(async (res) => {
        if (res) {
          return api.request(originalRequest);
        } else {
          return Promise.reject(axiosError);
        }
      });
    } else {
      return Promise.reject(axiosError)
    }
  };

  async appRequestSuccessInterceptor(config: InternalAxiosRequestConfig) {
    config.headers.partnerId = process.env.CORPORATE_ID;
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      config.headers.Authorization = undefined;
      return config;
    }
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  };

  async refreshToken() {
    const refToken = localStorage.getItem("refreshToken");
    const deviceId = localStorage.getItem("deviceId");
    if (!refToken || !deviceId) return false;
    try {
      const response = await axios.post<ILoginResponse>(`${process.env.API_URL}/auth/login/refresh_token`, {
        refreshToken: refToken,
        deviceId,
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      return true;
    } catch (e) {
      const authStore = useAuthStore();
      await authStore.logOut();
    }
    return false;
  };

  getFormattedAuthHeader() {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken || undefined;
  }
}

export const axiosInterceptorsService = new AxiosInterceptorsService();
