// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import {v4 as uuidv4} from "uuid";

export const useUuid = () => {
  const generateAndSetToLS = (field: string) => {
    const uuid = uuidv4();
    localStorage.setItem(field, uuid);
    return uuid;
  }

  return {
    generateAndSetToLS
  }
}
