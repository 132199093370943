export enum ExceptionsEnum {
  NOT_VALID_EMAIL = "1-e-fe",
  VALUE_MUST_BE_LESS_THAN = "2-e-fe",
  WRONG_PASSWORD_FORMAT = "3-e-fe",
  PASSWORDS_MISMATCH = "4-e-fe",
  FIELD_IS_REQUIRED = "5-e-fe",
  VALUE_MUST_BE_MORE_THAN = "6-e-fe",
  MAX_VALUE_IS = "7-e-fe",
  MIN_VALUE_IS = "8-e-fe",
  SERVER_TECHNICAL_WORKS = "9-e-fe",
  UNKNOWN_ERROR_OCCURRED = "10-e-fe",
  INTERNAL_SERVER_ERROR = "11-e-fe",
  CONFIRM_REGISTRATION_CODE_IS_NOT_PRESENTED = "12-e-fe",
  GENDER_IS_NOT_SPECIFIED = "13-e-fe",
  NAME_IS_NOT_SPECIFIED = "14-e-fe",
  RESET_PASSWORD_CODE_IS_NOT_PRESENTED = "15-e-fe",
  AVATAR_VALIDATION_ERROR = "16-e-fe",
  NO_CHARACTER_SELECTED = "17-e-fe",
  NO_TRAIT_SPECIFIED = "18-e-fe",
  FILE_TOO_LARGE = "19-e-fe",
  CHARACTER_CREATION_FAILED = "20-e-fe",
  IMAGE_GENERATION_FAILED = "21-e-fe",
}
