<template>
<q-icon>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 12C7.10217 12 6.72064 12.158 6.43934 12.4393C6.15804 12.7206 6 13.1022 6 13.5V27C6 27.3978 6.15804 27.7794 6.43934 28.0607C6.72064 28.342 7.10218 28.5 7.5 28.5H28.5C28.8978 28.5 29.2794 28.342 29.5607 28.0607C29.842 27.7794 30 27.3978 30 27V13.5C30 13.1022 29.842 12.7206 29.5607 12.4393C29.2794 12.158 28.8978 12 28.5 12H7.5ZM4.31802 10.318C5.16193 9.47411 6.30653 9 7.5 9H28.5C29.6935 9 30.8381 9.47411 31.682 10.318C32.5259 11.1619 33 12.3065 33 13.5V27C33 28.1935 32.5259 29.3381 31.682 30.182C30.8381 31.0259 29.6935 31.5 28.5 31.5H7.5C6.30653 31.5 5.16193 31.0259 4.31802 30.182C3.47411 29.3381 3 28.1935 3 27V13.5C3 12.3065 3.47411 11.1619 4.31802 10.318Z" fill="#E64343"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 6C14.6022 6 14.2206 6.15804 13.9393 6.43934C13.658 6.72064 13.5 7.10218 13.5 7.5V10.5C13.5 11.3284 12.8284 12 12 12C11.1716 12 10.5 11.3284 10.5 10.5V7.5C10.5 6.30653 10.9741 5.16193 11.818 4.31802C12.6619 3.47411 13.8065 3 15 3H21C22.1935 3 23.3381 3.47411 24.182 4.31802C25.0259 5.16193 25.5 6.30653 25.5 7.5V10.5C25.5 11.3284 24.8284 12 24 12C23.1716 12 22.5 11.3284 22.5 10.5V7.5C22.5 7.10218 22.342 6.72064 22.0607 6.43934C21.7794 6.15804 21.3978 6 21 6H15Z" fill="#E64343"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 16.5C18.8284 16.5 19.5 17.1716 19.5 18V18.015C19.5 18.8434 18.8284 19.515 18 19.515C17.1716 19.515 16.5 18.8434 16.5 18.015V18C16.5 17.1716 17.1716 16.5 18 16.5Z" fill="#E64343"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16029 18.8253C3.53309 18.0855 4.43503 17.788 5.17484 18.1608C9.15284 20.1653 13.5453 21.2095 17.9998 21.2095C22.4543 21.2095 26.8468 20.1653 30.8248 18.1608C31.5646 17.788 32.4666 18.0855 32.8394 18.8253C33.2122 19.5651 32.9146 20.4671 32.1748 20.8399C27.7781 23.0554 22.9232 24.2095 17.9998 24.2095C13.0764 24.2095 8.22158 23.0554 3.82484 20.8399C3.08503 20.4671 2.7875 19.5651 3.16029 18.8253Z" fill="#E64343"/>
  </svg>
</q-icon>
</template>
