import { boot } from "quasar/wrappers";
import TwicPics from "@twicpics/components/vue3";
import "@twicpics/components/style.css";


export default boot(({ app }) => {
  app.use(TwicPics, {
    domain: "https://desired.twic.pics"
  });
});
