
import { RoutePathsEnum } from "src/utils/enums/general/RoutePathsEnum";
import {useLanguageStore} from "stores/language";

export const useAppLink = () => {
  const language = useLanguageStore();

  const languageLayoutLink = () => `/${language.getLanguage?.value ? `${language.getLanguage.value}/` : ""}`;
  const myAiLink = () => `${languageLayoutLink()}${RoutePathsEnum.MY_AI}`;

  const homeLink = () => RoutePathsEnum.APP_ROOT;
  const signInLink = () => `${languageLayoutLink()}${RoutePathsEnum.SIGN_IN}`;
  const oauthStatsLink = () => `${languageLayoutLink()}${RoutePathsEnum.OAUTH_STATS}`;
  const notFoundLink = () => `${languageLayoutLink()}${RoutePathsEnum.NOT_FOUND}`;
  const signUpLink = () => `${languageLayoutLink()}${RoutePathsEnum.SIGN_UP}`;
  const findAccLink = () => `${languageLayoutLink()}${RoutePathsEnum.FIND_ACC}`;
  const maintenanceLink = () => `${languageLayoutLink()}${RoutePathsEnum.MAINTENANCE}`;
  const chatLink = () => `${languageLayoutLink()}${RoutePathsEnum.CHAT}`;
  const chatDetailsLink = (id: string) => `${languageLayoutLink()}${RoutePathsEnum.CHAT}/${id}`;
  const checkEmailLink = (email: string) => `${languageLayoutLink()}${RoutePathsEnum.CHECK_EMAIL}?email=${email}`;
  const buyTokensLink = () => `${languageLayoutLink()}${RoutePathsEnum.BUY_TOKENS}`;
  const buySubscriptionLink = () => `${languageLayoutLink()}${RoutePathsEnum.BUY_SUBSCRIPTION}`;
  const profileLink = () => `${languageLayoutLink()}${RoutePathsEnum.PROFILE}`;
  const characterProfileLink = (id: string) => `${languageLayoutLink()}${RoutePathsEnum.PROFILE}/${id}`;
  const generateImageLink = (characterId?: string) => `${languageLayoutLink()}${RoutePathsEnum.GENERATE}${characterId ? `/${characterId}` : ""}`;
  const createMyAiLink = (fromGenerate?: boolean) => `${languageLayoutLink()}${RoutePathsEnum.CREATE_MY_AI}${fromGenerate ? "?fromGenerate=true" : ""}`;
  const createMyAiResultLink = (id: string) => `${languageLayoutLink()}${RoutePathsEnum.CREATE_MY_AI_RESULT}/${id}`;
  const affiliateLink = () => `${languageLayoutLink()}${RoutePathsEnum.AFFILIATE}`;
  const communityLink = () => `${languageLayoutLink()}${RoutePathsEnum.COMMUNITY}`;
  const myAiLikedLink = () => `${myAiLink()}/${RoutePathsEnum.LIKED}`;
  const myAiGalleryLink = () => `${myAiLink()}/${RoutePathsEnum.GALLERY}`;
  const aboutUsLink = () => `${languageLayoutLink()}${RoutePathsEnum.ABOUT_US}`;
  const legalInfoLink = () => `${languageLayoutLink()}${RoutePathsEnum.LEGAL}`;
  const termsOfServiceLink = () => `${languageLayoutLink()}${RoutePathsEnum.TERMS}`;
  const privacyPolicyLink = () => `${languageLayoutLink()}${RoutePathsEnum.POLICY}`;
  const cookiesPolicyLink = () => `${languageLayoutLink()}${RoutePathsEnum.COOKIES}`;
  const contentRemovalLink = () => `${languageLayoutLink()}${RoutePathsEnum.CONTENT_REMOVAL}`;
  const blockedContentLink = () => `${languageLayoutLink()}${RoutePathsEnum.BLOCKED_CONTENT}`;
  const exemptionLink = () => `${languageLayoutLink()}${RoutePathsEnum.EXEMPTION}`;
  const dmcaLink = () => `${languageLayoutLink()}${RoutePathsEnum.DMCA}`;
  const complaintLink = () => `${languageLayoutLink()}${RoutePathsEnum.COMPLAINT}`;
  const sitemapLink = () => `${languageLayoutLink()}${RoutePathsEnum.SITEMAP}`;

  const confirmLoginLink = (email: string, deviceId: string, securityToken: string) => `${languageLayoutLink()}${RoutePathsEnum.CONFIRM_LOGIN}?email=${email}&deviceId=${deviceId}&securityToken=${securityToken}`;

  return {
    signInLink,
    signUpLink,
    findAccLink,
    confirmLoginLink,
    homeLink,
    checkEmailLink,
    chatLink,
    chatDetailsLink,
    buyTokensLink,
    profileLink,
    characterProfileLink,
    generateImageLink,
    createMyAiLink,
    myAiLink,
    affiliateLink,
    communityLink,
    myAiLikedLink,
    myAiGalleryLink,
    buySubscriptionLink,
    createMyAiResultLink,
    maintenanceLink,
    notFoundLink,
    aboutUsLink,
    legalInfoLink,
    termsOfServiceLink,
    privacyPolicyLink,
    cookiesPolicyLink,
    contentRemovalLink,
    blockedContentLink,
    exemptionLink,
    sitemapLink,
    dmcaLink,
    complaintLink,
    oauthStatsLink
  };
};
