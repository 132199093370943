import { defineStore } from 'pinia';
import { IUser } from 'src/types/user/IUser';
import { GenderEnum } from 'src/utils/enums/user/GenderEnum';
import { useAuthStore } from 'stores/auth';

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    user: null as IUser | null,
    avatar: "",
    userInfoLoading: false,
    avatarLoading: false,
    advantage: GenderEnum.FEMALE,
  }),
  getters: {
    getId(): string | null {
      return this.user?.userId || null;
    },
    getCharacterTrialEnabled(): boolean {
      return !!this.user?.characterTriaEnabled;
    },
    getImageTrialEnabled(): boolean {
      return !!this.user?.imageTrialEnabled;
    },
    getUserInfoLoading(): boolean {
      return this.userInfoLoading;
    },
    getAvatarLoading(): boolean {
      return this.avatarLoading;
    },
    getAdult(): boolean {
      return this.user?.adult || false;
    },
    getNickname(): string {
      return this.user?.nickname || "";
    },
    getEmail(): string {
      return this.user?.email ? this.user.email.includes("twitter") ? "" : this.user.email : "";
    },
    getGender(): GenderEnum | null {
      return this.user?.gender || null;
    },
    getIsPublicAi(): boolean {
      return this.user?.publicAi || false;
    },
    getAdvantage(): GenderEnum | null | "loading" {
      const authStore = useAuthStore();
      return authStore.getIsAuthorized && !this.user ? "loading" : this.advantage as GenderEnum;
    },
    getAvatar(): string {
      return this.avatar ? `data:image/png;base64, ${this.avatar}` : "";
    },
  },
  actions: {
    customReset() {
      this.user = null;
      this.avatar = "";
      this.userInfoLoading = false;
      this.avatarLoading = false;
    },
    setUser(user: IUser) {
      this.user = user;
      this.setAdvantage(user.interestedIn);
    },
    setPublicAi(val: boolean) {
      if (!this.user) return;
      this.user.publicAi = val;
    },
    setUserInfoLoading(val: boolean) {
      this.userInfoLoading = val;
    },
    setAvatarLoading(val: boolean) {
      this.avatarLoading = val;
    },
    setNickname(val: string) {
      if (!this.user) return;
      this.user.nickname = val;
    },
    setEmail(val: string) {
      if (!this.user) return;
      this.user.email = val;
    },
    setGender(val: GenderEnum) {
      if (!this.user) return;
      this.user.gender = val;
    },
    setAdult(val: boolean) {
      if (!this.user) return;
      this.user.adult = val;
    },
    setAdvantage(val: GenderEnum) {
      // localStorage.setItem("advantage", val);
      this.advantage = GenderEnum.FEMALE;
    },
    setUserAvatar(avatar: string) {
      this.avatar = avatar;
    },
  },
});
