import { defineStore } from "pinia";
import {ICurrentPlan} from "src/types/purchase/ICurrentPlan";
import {SubscriptionEnum} from "src/utils/enums/purchase/SubscriptionEnum";

export const useSubscriptionStore = defineStore({
  id: "subscription",
  state: () => ({
    subscription: null as ICurrentPlan | null,
    subscriptionLoading: false,
    subscriptionRefreshKey: 0,
  }),
  getters: {
    getIsActiveSubscription(): boolean {
      return !!this.subscription;
    },
    getSubscriptionLoading(): boolean {
      return this.subscriptionLoading;
    },
    getLastPaymentDate(): string | undefined {
      return this.subscription?.lastPaymentDate;
    },
    getNextPaymentDate(): string | undefined {
      return this.subscription?.nextPaymentDate;
    },
    getType(): SubscriptionEnum | undefined {
      return this.subscription?.type;
    },
  },
  actions: {
    setSubscription(val: ICurrentPlan) {
      this.subscription = val;
    },
    setSubscriptionLoading(val: boolean) {
      this.subscriptionLoading = val;
    },
  },
});
