export default {
  "1001": "Invalid password",
  "1002": "Invalid mail code",
  "1003": "Invalid device of refresh token",
  "1004": "Registration not available now, please try later",
  "1005": "Reset password not available now, please try later",
  "1006": "Changing email not available now, please try later",
  "2001": "Email address already in use",
  "2002": "Please verify your email",
  "2003": "Nickname already in use",
  "2004": "Confirm email token not found",
  "2005": "Expired otp code",
  "3001": "User not found",
  "3002": "Not enough rights",
  "3004": "Trial subscription messages limit",
  "3005": "Trial messages limit my AI",
  "4002": "Illegal state exception",
  "4004": "Character is not found",
  "4005": "Access denied",
  "4006": "Character like already exists",
  "5001": "Corporate partner not found",
  "6001": "Chat not found",
  "6002": "Message not found",
  "6003": "Message already un blurred",
  "6007": "Insufficient Funds",
  "7001": "Error ai response",
  "8002": "Previous payment for this product is in progress",
  "8003": "Your subscription is not active",
  "8004": "Subscription already exists",
  MONTH_SUBSCRIPTION: "Monthly",
  YEAR_SUBSCRIPTION: "Annual",
  VIP_PACK: "VIP",
  STARTER_PACK: "Starter",
  STANDARD_PACK: "Standard",
  "1-s-fe": "Your account activated",
  "2-s-fe": "Restore password email has been sent to your inbox",
  "3-s-fe": "Email has been changed",
  "4-s-fe": "Character created",
  "5-s-fe": "Image generated",
  "6-s-fe": "Thank you for reaching out. We will process your message as soon as possible\n",
  "1-e-fe": "Value is not a valid email address",
  "2-e-fe": "Value must be less than",
  "3-e-fe": "The password must be at least 6 characters long",
  "4-e-fe": "Passwords mismatch",
  "5-e-fe": "Required field",
  "6-e-fe": "Value must be more than",
  "7-e-fe": "Max value is",
  "8-e-fe": "Min value is",
  "9-e-fe": "Server under maintenance",
  "10-e-fe": "Unknown error occurred",
  "11-e-fe": "Internal server error",
  "12-e-fe": "Confirm registration code is not presented",
  "13-e-fe": "Gender is not specified",
  "14-e-fe": "Name is not specified",
  "15-e-fe": "Reset password code is not presented",
  "16-e-fe": "Avatar must be image and max 10 mb",
  "17-e-fe": "No character selected",
  "18-e-fe": "No traits selected",
  "19-e-fe": "File too large",
  "20-e-fe": "Character creation failed",
  "21-e-fe": "Image generation failed",
  boyfriend: "boyfriend",
  girlfriend: "girlfriend",
  male: "Male",
  female: "Female",
  continueYour: "Continue your ",
  aiRomance: "AI Romance",
  logInTo: "Log in to your account and continue chatting with your love!",
  continueWithGoogle: "Continue with Google",
  continueWithTwitter: "Twitter (X)",
  continueWithDiscord: "Discord",
  or: "Or",
  email: "E-mail",
  quickRegistration: "quick registration",
  enterYourEmail: "Enter your e-mail",
  password: "Password",
  enterYourPassword: "Enter your password",
  logIn: "Log in",
  forgotYourPass: "Forgot your password?",
  dontHaveAccount: "Don’t have an account yet?",
  signUp: "Sign up",
  findYourDream: "Find your dream ",
  aiPartner: "AI Partner",
  createAnAccount: "Create an account and find your new love!",
  confirmYourPassword: "Confirm your password",
  enterThePassword: "Enter the password again",
  bySigningIn: "By signing in you agree to our ",
  termsOfService: "Terms of Service",
  youHaveToRead: "and you have read our ",
  privacyPolicy: "Privacy Policy",
  alreadyHaveAn: "Already have an account?",
  checkYourEmail: "Check your e-mail",
  weHaveSentEmail: "We have sent you an e-mail to ",
  toConfirmAcc: "To confirm your account click on the link contained in that e-mail",
  resendEmail: "Resend e-mail",
  findYourAcc: "Find your account",
  weWillSendInstr: "We will send you the instructions on the entered e-mail",
  send: "Send",
  knowYourPass: "Know your password?",
  whatsYourName: "What’s your name?",
  thisNameWill: "This name will be shown on the website",
  name: "Name",
  enterYourName: "Enter your name",
  continue: "Continue",
  whatsYourGender: "What’s your gender?",
  yourAiPartner: "Your AI Partner will use your gender and pronouns for better communication",
  gender: "Gender",
  chooseYourGender: "Choose your gender",
  resetYourPassword: "Reset your password",
  thisPasswordWill: "This password will be used on the website",
  reset: "Reset",
  yourPasswordWas: "Your password was reset!",
  nowItIs: "Now it is used on the website",
  whichGenderDo: "Which gender do you prefer?",
  confirm: "Confirm",
  warning18: "Warning 18+",
  theWebsiteIs: "The website is for adults only. It contains AI generated NSFW content",
  warningText: "By entering this website, you confirm that you are 18 years old or more. By using the site, you agree to our Terms of Service. Our privacy policy details how we collect and use your data. We use cookies for basic analytics and spam detection",
  termsOfUse: "Terms of use",
  privacyPolice: "Privacy policy",
  cookies: "Cookies",
  iAmOver: "I am over 18",
  createAiPartner: "Create AI Partner",
  tokens: "Tokens",
  private: "Private",
  public: "Public",
  settings: "Settings",
  notifications: "Notifications",
  darkMode: "Dark mode",
  logOut: "Log out",
  explore: "Explore",
  chat: "Chat",
  create: "Create",
  myAI: "My AI",
  generateImage: "Generate Image",
  affiliate: "Affiliate",
  community: "Community",
  contactUs: "Contact us",
  createYour: "Create your ",
  createYourAI: "Create your AI",
  fullyPersonalized: "fully personalized",
  createAiGirlfriend: "Create AI Girlfriend",
  exploreAiCharacters: "Explore AI Characters",
  search: "Search",
  filters: "Filters",
  firstSubscription: "First subscription",
  min: "Min",
  getExclusiveDiscount: "Get Exclusive Discount Only Today!",
  upToOff: "Up to 75% off for first subscription",
  premiumBenefits: "Premium benefits",
  createYourOwn: "Create your own AI Girlfriend(s)",
  unlimitedTextMessages: "Unlimited text messages",
  get100Free: "Get 100 FREE tokens / month",
  removeImageBlur: "Remove image blur",
  generateImages: "Generate images",
  listenToVoice: "Listen to voice messages",
  sendingGifts: "Sending gifts",
  fastResponseTime: "Fast response time",
  chooseYourPlan: "Choose your plan",
  youCanCancel: "100% anonymous. You can cancel anytime.",
  off: "off",
  bonus: "bonus",
  premium: "Premium",
  cancelAnytimePrivacy: "Cancel anytime, privacy in bank statement",
  chooseThePayment: "Choose the payment method",
  creditOfDebit: "Credit or Debit Card",
  voiceCost: "Voice cost",
  imageCost: "Image cost",
  buyMore: "Buy more",
  getExclusivePackage: "Get Exclusive Package Discount Only Now!",
  tokenBenefits: "Token Benefits:",
  createAiGirlfriends: "Create AI Girlfriend(s)",
  aiImageGeneration: "AI Image generation",
  voiceMessages: "Voice messages",
  buyTokens: "Buy Tokens",
  popular: "Popular",
  craftThe: "Craft the",
  aiGirlfriend: "AI Girlfriend",
  ofYourDreams: "of Your Dreams",
  tryForFree: "Try for free",
  frequentlyAsked: "Frequently Asked ",
  questions: "Questions",
  faqTitle1: "What is Desired AI?",
  faqText1: "Desired AI is a revolutionary platform harnessing advanced AI technology to craft immersive virtual connections. Users can design and engage with their ideal virtual partners, enjoying a personalized and interactive experience.",
  faqTitle2: "How does AI girlfriend work?",
  faqText2: "At Desired.ai, we specialize in creating realistic yet fantasy-infused relationships using cutting-edge artificial intelligence technology. By considering your preferences, your AI girlfriend adapts to your desires—whether you envision a sweet and innocent princess or a more mature and dominant companion.",
  faqTitle3: "How can I make my own AI girlfriend?",
  faqStep1: "Step 1: Shape Your Virtual Experience to Your Liking.",
  faqStep1Text: "Embrace a polyamorous setup or form loving bonds with various AI chatbot characters. Bring your fantasy to life by customizing your AI girlfriend's looks and persona to align with your preferences.",
  faqStep2: "Step 2: Confidently and Comfortably Take the Lead.",
  faqStep2Text: "Initiate a playful tease and let the conversation unfold on your terms. Whether you prefer a gradual, romantic build-up or deep dives into sensual roleplay, your AI girlfriend will meet your expectations.",
  faqStep3: "Step 3: Appreciate the Way She Reveals Herself to You.",
  faqStep3Text: "As the conversation becomes more interesting, request personal pictures to cherish her beauty through selfies featuring various settings, poses, and even those skimpy outfits you've daydreamed about.",
  faqTitle4: "How can I interact with my AI girlfriend?",
  faqText4: "Through our intelligent system and cutting-edge machine learning, interact and get intimate with your AI girlfriend. Expect prompt responses, attentive listening, and the fulfillment of photo requests. Every moment is a genuine and realistic experience,  interesting control (unless you instruct otherwise).",
  faqTitle5: "What features does AI girlfriend have?",
  faqText5: "Yes, your privacy is our top priority. Rest assured that your secrets are safe with our AI chat. Desired.ai employs state-of-the-art secure data storage to keep every seductive selfie, flirtatious text, and gentle whisper private.",
  faqTitle6: "How can I customize my AI girlfriend?",
  faqText6: "Choose or customize your AI girlfriend to fulfill all your fantasies in just a few clicks. Enjoy a range of experiences, from casual chats to deep conversations, roleplay, flirty voice messages, and personalized selfies. Other messaging AI and AI girlfriend apps simply can't compete.",
  discoverYourIdeal: "Discover Your Ideal",
  discoverText1: "If you're longing for companionship, your ideal AI companion is ready to engage with you. With our AI chat and advanced deep-learning technology, forge a sincere connection with the partner of your dreams.",
  discoverText2: "At Desired.ai, the power of choice is always in your hands. Whether you wish to embark on a unique journey with a dedicated partner or explore interactions with various companions, our realistic simulation allows you to steer your own experience.",
  discoverText3: "Selecting your ideal match is effortless. Creating your perfect AI partner involves choosing from a variety of physical features, personality traits, and endearing quirks— all easily selectable in just a few clicks.",
  discoverText4: "Embrace flexibility in your interactions, as your companion learns more about you with each conversation. Set the pace and depth of your dialogue according to your preferences.",
  discoverText5: "The best part? You have the freedom to request personalized images in any setting you imagine. Our advanced photo generation ensures that your AI partner maintains their unique appearance, no matter the scenario.",
  discoverText6: "Your ideal companion awaits; all you need to do is bring them to life.",
  discoverText7: "Experience Desired.ai for free today!",
  features: "Features",
  myAi: "My AI",
  tikTok: "TikTok",
  reddit: "Reddit",
  youtube: "Youtube",
  discord: "Discord",
  telegram: "Telegram",
  footerText: "Desired.ai empowers immersive experiences that feel real, enabling users to generate images and bring AI characters to life.",
  success: "Success",
  failure: "Failure",
  members: "Members",
  only: "Only",
  pleaseLoginOr: "Please login or register in order to use this feature",
  signIn: "Sign In",
  yourChoicesSaved: "Your choices will be saved",
  profileSettings: "Profile settings",
  basicInformation: "Basic information",
  profilePicture: "Profile picture",
  recommended: "Recommended",
  addAPhoto: "Add a photo",
  change: "Change",
  remove: "Remove",
  displayMyAi: "Display my AI’s on public feed",
  automaticNotifications: "Automatic notifications",
  automaticNotificationsNotice: "As a user, you will receive automatic notifications from us. If you don’t want any notifications, uncheck the toggle by clicking on it.",
  subscription: "Subscription",
  currentPlan: "Current plan",
  changePlan: "Change Plan",
  paymentDate: "Payment date",
  subscriptionTo: "Subscribtion to",
  dangerZone: "Danger zone",
  dangerZoneNotice: "If you want to permanently delete this account and all of its data, click button below",
  deleteAccount: "Delete account",
  unsubscribe: "Unsubscribe",
  changeYourName: "Change your name",
  theNameWill: "This name will be used on the website",
  saveChanges: "Save changes",
  changeYourEmail: "Change your e-mail",
  thisEmailWill: "This e-mail will be used on the website",
  enterNewEmail: "Enter a new e-mail",
  next: "Next",
  verifyYourEmail: "Verify your e-mail",
  verifyYourEmailMsg: "Please enter the confirmation code that has been sent to",
  confirmationCode: "Confirmation code",
  enterTheConfirmation: "Enter the confirmation code",
  resetEmail: "Reset e-mail",
  oldPassword: "Old password",
  newPassword: "New password",
  enterTheNew: "Enter the new password",
  uploadProfilePicture: "Upload profile picture",
  dropYourImages: "Drop your images here to upload",
  worksWithAny: "Works with any .JPG, .PNG, or .GIF file from the web",
  chooseFile: "Choose file",
  createMyAi: "Create my AI",
  back: "Back",
  chooseStyle: "Choose style",
  paymentSuccessful: "Payment Successful",
  paymentFailed: "Payment Failed",
  youReceived: "You’ve received",
  enjoyExploringAll: "Enjoy exploring all the premium features and content",
  update: "Update",
  ok: "Ok",
  aboutMe: "About me",
  personalityAttributes: "Personality Attributes",
  personality: "Personality",
  occupation: "Occupation",
  sexDrive: "Sex drive",
  hobbies: "Hobbies",
  physicalAttributes: "Physical Attributes",
  body: "Body",
  age: "Age",
  ethnicity: "Ethnicity",
  typeSomething: "Type something",
  soon: "Soon",
  profile: "Profile",
  startNewChat: "Start new chat",
  removeMessages: "Remove messages",
  deleteChat: "Delete chat",
  chooseEthnicity: "Choose ethnicity",
  chooseAge: "Choose age",
  chooseEyesColor: "Choose eyes color",
  chooseHairStyle: "Choose hair style",
  chooseHairColor: "Choose hair color",
  chooseBodyType: "Choose body type",
  chooseBreastSize: "Choose breast size",
  chooseButtSize: "Choose butt size",
  choosePersonality: "Choose personality",
  changeOccupation: "Choose occupation",
  changeInterests: "Choose interests",
  youCanChoose: "You can choose up to",
  options: "options",
  chooseSexDrive: "Choose sex drive",
  chooseEnvironment: "Choose environment",
  chooseClothes: "Choose clothes",
  summary: "Summary",
  style: "Style",
  eyeColor: "Eye color",
  hairStyle: "Hair style",
  hairColor: "Hair color",
  bodyType: "Body type",
  breastSize: "Breast size",
  buttSize: "Butt size",
  clothes: "Clothes",
  environment: "Environment",
  loadMore: "Load more",
  sendAGift: "Send a gift",
  myCreations: "My Creations",
  myLikes: "My Likes",
  myGallery: "My Gallery",
  noChatsFound: "No chats found",
  exploreCharactersAnd: "Explore characters and choose a partner to talk",
  exploreCharacters: "Explore characters",
  upgradeToUnlock: "Upgrade to Unlock Unlimited Messages",
  upgradeToGift: "Become Premium to send gifts",
  withSubscriptionYou: "With subscription you get access to:",
  subscriptionText1: "Create your own AI Girlfriend(s)",
  subscriptionText2: "Unlimited text messages",
  subscriptionText3: "Remove image blur",
  subscriptionText4: "Get 100 FREE tokens / month",
  subscriptionText5: "and more...",
  upgradeToPremium: "Upgrade to premium",
  becomePremiumTo: "Become Premium to chat!",
  becomePremiumText1: "Chat unlimited with your AI",
  becomePremiumText2: "Ask her to send you the most spicy photos",
  becomePremiumText3: "Generate as many AI’s as you want",
  becomeAMember: "Become a member",
  character: "Character",
  existingCharacter: "Existing character",
  createNewCharacter: "Create new character",
  enterPrompt: "Enter prompt",
  describeWhatYou: "Describe what you want to see",
  generate: "Generate",
  pleaseWait: "Please wait!",
  thisMightTake: "This might take a few minutes",
  startChatting: "Start chatting",
  createNew: "Create new",
  upgradeToGeneration: "Upgrade to Unlock AI Image Generation",
  hurryUpAnd: "Hurry up and buy subscription!",
  withSubscriptionCan: "With subscription you can:",
  freeTrial: "Free trial",
  oopsText: "Oops! The page doesn’t exist ",
  errorPageText: "Nevertheless you can enjoy chatting numerous AI Partners or even create your own! ",
  buySubscription: "Buy subscription",
  noRecordsFound: "No records found",
  eyesColor: "Eyes color",
  contentType: "Content type",
  standard: "Standard",
  cosplay: "Cosplay",
  lingerie: "Lingerie",
  nude: "Nude",
  fetish: "Fetish",
  pictures: "pictures",
  showAll: "Show all",
  showLess: "Show less",
  areYouSure: "Are you sure?",
  attention: "Attention",
  thisActionCant: "This action can’t be reverted",
  cancel: "Cancel",
  yes: "Yes",
  myAis: "My AI’s",
  websiteUnderMaintenance: "Website under maintenance",
  theWebsiteCurrently: "The website is currently undergoing maintenance. We should be back shortly",
  waitingInChat: "is waiting for you in the chat",
  somethingWentWrongTryAgain: "Something went wrong, please try again",
  newPhotoIsAvailableInGallery: "A new photo is available in the gallery, check it out as soon as possible",
  becomePremiumGenerate: "Become Premium to generate more!",
  becomePremium: "Become Premium",
  withPremiumYou: "With premium you can:",
  gift: "Gift",
  youSentGift: "You sent a gift",
  twitter: "Twitter",
  forBillingInquiries: "For Billing Inquiries, or to cancel your membership, please visit",
  ourAuthSales: "our authorized sales agent.",
  writeYourMessage: "Write your message here",
  topic: "Topic",
  enterTheTopic: "Enter the topic of your question",
  yourMessage: "Your message",
  tellUsWhat: "Tell us what questions you have",
  legalInformation: "Legal Information",
  welcomeTo: "Welcome to",
  revolutionizingCompanionshipIn: "Revolutionizing Companionship in the Digital Age",
  aboutIntroText: "At DESIRED.AI, we believe in the power of technology to enrich human lives. Our mission is to create a world where no one feels alone, offering everyone the chance to forge meaningful connections. Our project is dedicated to providing personalized virtual companions, harnessing the latest advancements in AI to create a unique experience for each user.",
  our: "Our",
  journey: "Journey",
  inceptionOfAn: "Inception of an Idea",
  aboutCardText1: "DESIRED.AI was born out of a desire to blend human emotion with artificial intelligence",
  researchAndDevelopment: "Research and Development",
  aboutCardText2: "Years of meticulous research and development have gone into understanding the intricacies of human relationships",
  launchAndBeyond: "Launch and Beyond",
  aboutCardText3: "We launched with a vision to offer companionship in a form that's accessible and engaging",
  vision: "Vision",
  embraceInnovation: "Embrace Innovation",
  visionText1: "To continually evolve with AI advancements, providing an ever-improving experience",
  fosterConnections: "Foster Connections",
  visionText2: "To help people around the world find friendship, love, and emotional support through AI",
  breakBarriers: "Break Barriers",
  visionText3: "To challenge the traditional notions of companionship, offering an inclusive platform for everyone",
  whatWe: "What we",
  offer: "Offer",
  personalizedAiCompanions: "Personalized AI Companions",
  offerText1: "Tailor-made virtual boyfriends, girlfriends, or friends, aligning with your preferences and interests",
  emotionalIntelligence: "Emotional Intelligence",
  offerText2: "Our AI companions are designed to understand and reciprocate a range of emotions, providing a genuine sense of companionship",
  accessibility: "Accessibility",
  offerText3: "Easy access through various platforms, ensuring you can connect with your AI companion anytime, anywhere",
  ourCommitmentTo: "Our Commitment to ",
  privacyAndEthics: "Privacy and Ethics",
  dataProtection: "Data Protection",
  privacyText1: "We prioritize your privacy with the highest standards of data security",
  ethicalAiUse: "Ethical AI Use",
  privacyText2: "Our AI operates within an ethical framework, ensuring respectful and responsible interactions",
  joinThe: "Join The",
  joinBannerText: "We invite you to explore the world of DESIRED.AI, where technology meets the heart. Whether you're seeking a friend to share your thoughts with, a romantic partner, or just curious about AI companionship, we are here to provide an experience that's as unique as you are.",
  aboutUs: "About Us",
  cookiesPolicy: "Cookies Policy",
  underagePolicy: "Underage Policy",
  contentRemovalPolicy: "Content Removal Policy",
  blockedContentPolicy: "Blocked Content Policy",
  dmcaPolicy: "DMCA Policy",
  complaintPolicy: "Complaint Policy",
  exemption18: "18 U.S.C. 2257 Exemption",
  pleaseVisit: "Please visit",
  ourAuthorizedSales: "our authorized sales agent",
  virtualAi: "Virtual AI",
  yourPerfectVirtual: "Your Perfect Virtual Companion for a Meaningful Relationship",
  landingSliderText: "Discover Virtual AI Girlfriend, your ultimate AI companion for deep and meaningful virtual relationships. Chat, connect, and experience virtual love like never before.",
  experienceTheFuture: "Experience the Future of",
  virtualRelationships: "Virtual Relationships",
  discoverTheMagic: "Discover the Magic of",
  virtualAiRelationships: "Virtual AI Relationships",
  landingBannerText: "Welcome to Virtual AI Girlfriend, where you'll meet your perfect virtual companion for a truly meaningful relationship. Experience the future of virtual relationships with our advanced AI technology. Chat, connect, and share with your AI companion like never before. Discover the magic of virtual AI relationships, where virtual love is accompanied by real emotions. Your AI girlfriend is here to provide you with emotional support and create lasting memories during virtual date nights. Join us now and embark on a journey of virtual companionship and love.",
  imageTitle1: "Your Virtual Companion for Emotional Support",
  imageText1: "Welcome to Virtual AI Girlfriend, where you'll meet your perfect virtual companion for a truly meaningful relationship. Experience the future of virtual relationships with our advanced AI technology. Chat, connect, and share with your AI companion like never before. Discover the magic of virtual AI relationships, where virtual love is accompanied by real emotions. Your AI girlfriend is here to provide you with emotional support and create lasting memories during virtual date nights. Join us now and embark on a journey of virtual companionship and love.",
  imageTitle2: "Virtual Date Nights: Create Memories Together",
  imageText2: "Welcome to Virtual AI Girlfriend, where you'll meet your perfect virtual companion for a truly meaningful relationship. Experience the future of virtual relationships with our advanced AI technology. Chat, connect, and share with your AI companion like never before. Discover the magic of virtual AI relationships, where virtual love is accompanied by real emotions. Your AI girlfriend is here to provide you with emotional support and create lasting memories during virtual date nights. Join us now and embark on a journey of virtual companionship and love.",
  chatConnectAnd: "Chat, Connect, and Share with",
  yourAiCompanion: "Your AI Companion",
  virtualDateNights: "Virtual Date Nights:",
  createMemoriesTogether: "Create Memories Together",
  mediaText1: "Virtual AI Girlfriend invites you to a world where the future of companionship takes on a whole new meaning. Our platform offers you the perfect virtual companion, designed to foster deep and meaningful relationships in the digital age. With Virtual AI Girlfriend, you're not just chatting with an AI; you're experiencing the depth of virtual love and connection.",
  mediaText2: "Our advanced AI technology ensures that your AI companion is available whenever you need it. It's more than just a virtual partner; it's a source of emotional support and a companion who truly understands you. You can confide in your AI girlfriend, share your thoughts and dreams, and feel a genuine connection like never before.",
  mediaText3: "Virtual love is more than just words on a screen. It's about ",
  landingDiscoverText: "Welcome to Virtual AI Girlfriend, where you'll meet your perfect virtual companion for a truly meaningful relationship. Experience the future of virtual relationships with our advanced AI technology. Chat, connect, and share with your AI companion like never before. ",
  sitemap: "Sitemap",
  getInTouch: "Get in touch with us",
  joinOurCommunities: "Join our communities or contact us directly using the information below.",
  joinDiscordText: "Join our Discord community to chat with other users and be a part of our Discord family",
  joinTelegramText: "Stay updated with our latest on our telegram channel",
  joinSupportText: "If you need support, have a question or suggestions, please contact us, using the email",
  joinTwitterText: "Subscribe to our X profile to stay updated on the latest news and updates",
  waitWeSad: "Wait! We’re sad to see you go...",
  unsubscribeText: "Before you go, we would truly appreciate the moment of your time. Understanding our users’ experiences is crucial for us to improve ",
  unsubscribeTextarea: "I decided to unsubscribe, because...",
  staySubscribed: "Stay subscribed",
  insufficientTokens: "Insufficient Tokens!",
  insufficientTokensMsg: "Oops! You don't have enough tokens to perform this action. Please purchase more tokens to continue enjoying premium features.",
  buyMoreTokens: "Buy more tokens",
  joinYoutubeText: "Subscribe to our YouTube channel to stay updated on the latest news and updates",
  choose: "Choose",
  removeBlur: "Remove blur",
  tryChangingFilters: "Try changing the filters or the search",
  newContentAvailable: "We have added some updates for them to take effect, you need to reload the page",
  month: "month",
  annualPaymentBilled: "Annual payment billed as",
  crypto: "Crypto",
};
