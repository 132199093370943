import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {getRandomIntInclusive} from "src/utils/utils";
import {useQuasar} from "quasar";

interface ISaved {
  keyWord: string;
  bannerId: number;
}

export const LandingGuard = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  // const isSavedLandings = localStorage.getItem("visitedLandings");
  const isSavedLandings = null
  let savedLandings: ISaved[] = [];
  if (isSavedLandings) {
    savedLandings = JSON.parse(isSavedLandings) as ISaved[];
  }
  const isLandingVisited = savedLandings.find(i => i.keyWord === _to.params.keyWord);
  let bannerUrl;
  if (!isLandingVisited) {
    const val = {
      keyWord: _to.params.keyWord.toString(),
      bannerId: getRandomIntInclusive(1, 11)
    }
    savedLandings.push(val);
    // localStorage.setItem("visitedLandings", JSON.stringify(savedLandings));
    bannerUrl = `create-your-own-ai-girlfriend-${val.bannerId}.webp`;
  } else {
    bannerUrl = `create-your-own-ai-girlfriend-${isLandingVisited.bannerId}.webp`;
  }
  const img = new Image();
  const $q = useQuasar();
  console.log($q.screen.sm)
  if ($q.screen.xs) {
    img.src = `https://desired.twic.pics/${bannerUrl}?twic=v1/cover=4:3/resize=600`;
  }
  if ($q.screen.sm) {
    img.src = `https://desired.twic.pics/${bannerUrl}?twic=v1/cover=4:3/resize=1024`;
  }
  if ($q.screen.md) {
    img.src = `https://desired.twic.pics/${bannerUrl}?twic=v1/cover=16:9/resize=1440`;
  }
  if ($q.screen.lg) {
    img.src = `https://desired.twic.pics/${bannerUrl}?twic=v1/cover=16:9/resize=1920`;
  }
  if ($q.screen.xl) {
    img.src = `https://desired.twic.pics/${bannerUrl}?twic=v1/cover=16:9/resize=2000`;
  }
  _to.params.bannerUrl = bannerUrl;
  next();
};
