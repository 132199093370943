import {defineStore} from "pinia";
import {ThemesEnum} from "src/utils/enums/general/ThemesEnum";
import {ITheme} from "src/types/user/ITheme";

export const AppThemes: ITheme[] = [
  {
    value: ThemesEnum.LIGHT,
    class: "body--light",
  },
  {
    value: ThemesEnum.DARK,
    class: "body--dark",
  },
];

export const useThemeStore = defineStore({
  id: "theme",
  state: () => ({
    theme: null as null | ITheme,
  }),
  getters: {
    getTheme(): ITheme | null {
      return this.theme;
    },
    getThemeValue(): ThemesEnum | null {
      return this.theme?.value || null;
    },
  },
  actions: {
    setInitialTheme() {
      this.theme = AppThemes.find(i => i.value === localStorage.getItem("theme")) || AppThemes[0]
    },
    setTheme(val?: ThemesEnum) {
      const theme = val || localStorage.getItem("theme");
      switch (theme) {
        case ThemesEnum.LIGHT:
          document.body.classList.remove("body--dark");
          document.body.classList.add("body--light");
          break;
        case ThemesEnum.DARK:
          document.body.classList.remove("body--light");
          document.body.classList.add("body--dark");
          break;
        default:
          document.body.classList.remove("body--dark");
          document.body.classList.add("body--light");
      }
      this.theme = AppThemes.find(i => i.value === theme) || AppThemes[0];
      localStorage.setItem("theme", this.theme.value);
      console.log(this.theme);
    }
  }
});
