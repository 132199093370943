<template>
<q-icon>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1542_231523)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7609 30.4125C14.1324 31.8705 12.3834 32.712 10.7589 31.881C7.68991 30.3075 5.57791 28.509 4.28641 26.511C2.97991 24.4875 2.57641 22.362 2.75791 20.286C3.11191 16.2615 5.67691 12.435 7.80091 9.6C10.6269 5.832 14.2209 3.75 18.0009 3.75C21.7809 3.75 25.3749 5.832 28.2009 9.6C30.3024 12.402 33.0609 16.251 33.5109 20.2875C33.7434 22.383 33.3609 24.5355 31.9989 26.5785C30.6579 28.5915 28.4394 30.3885 25.1919 31.956C23.5674 32.7405 21.8349 31.8855 21.2274 30.4215C20.1444 31.1025 19.0104 31.5 18.0024 31.5C16.9884 31.5 15.8469 31.098 14.7594 30.4125H14.7609ZM10.2009 11.4C12.6249 8.169 15.4059 6.75 18.0009 6.75C20.5959 6.75 23.3769 8.169 25.8009 11.4C27.9819 14.307 30.1839 17.523 30.5289 20.6205C30.6924 22.0905 30.4314 23.52 29.5029 24.915C28.5639 26.322 26.8764 27.795 24.0009 29.2005V27.849C25.2999 26.124 26.2509 23.811 26.2509 21C26.2509 20.4255 26.2209 19.8675 26.1594 19.3305C26.1181 18.9668 25.9452 18.6308 25.6733 18.3858C25.4015 18.1407 25.0494 18.0035 24.6834 18C23.0407 17.9834 21.4603 17.3684 20.2385 16.2703C19.0166 15.1721 18.2371 13.6662 18.0459 12.0345C18.0186 11.8194 17.9454 11.6127 17.8313 11.4283C17.7172 11.2439 17.5648 11.0861 17.3845 10.9657C17.2042 10.8453 17.0001 10.7649 16.7861 10.7301C16.5721 10.6953 16.3531 10.7069 16.1439 10.764C12.5529 11.79 9.75091 15.6075 9.75091 21C9.75091 23.811 10.7019 26.124 12.0009 27.849V29.145C9.32041 27.753 7.71691 26.289 6.80641 24.8805C5.89591 23.4735 5.61691 22.0275 5.74741 20.55C6.01741 17.4675 8.05591 14.256 10.2009 11.4ZM12.7509 21C12.7509 17.667 14.0259 15.489 15.5154 14.382C16.0886 16.0687 17.1124 17.5665 18.476 18.713C19.8395 19.8594 21.4908 20.6109 23.2509 20.886C23.2689 22.998 22.5489 25.0695 21.1149 26.634C19.8669 27.9945 18.5499 28.5 18.0009 28.5C17.4519 28.5 16.1364 27.9945 14.8884 26.634C13.7079 25.3455 12.7509 23.4495 12.7509 21Z" fill="#D9452F"/>
    </g>
    <defs>
      <clipPath id="clip0_1542_231523">
        <rect width="36" height="36" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</q-icon>
</template>
