import { RouteRecordRaw } from "vue-router";
import {RoutePathsEnum} from "src/utils/enums/general/RoutePathsEnum";
import {RouteNamesEnum} from "src/utils/enums/general/RouteNamesEnum";
import {AuthGuard} from "src/router/guards/AuthGuard";
import {ChatDetailsGuard} from "src/router/guards/ChatDetailsGuard";
import {LandingGuard} from "src/router/guards/LandingGuard";

const routes: RouteRecordRaw[] = [
  {
    path: `/${RoutePathsEnum.LANGUAGE_LAYOUT}`,
    component: () => import(/* webpackChunkName: "language-layout" */ "src/layouts/LanguageLayout.vue"),
    children: [
      {
        path: RoutePathsEnum.APP_EMPTY,
        component: () => import( /* webpackChunkName: "main-layout" */ "src/layouts/MainLayout.vue"),
        children: [
          {
            name: RouteNamesEnum.HOME,
            path: RoutePathsEnum.APP_EMPTY,
            component: () => import(/* webpackChunkName: "home-page" */ "src/pages/HomePage.vue"),
          },
          {
            name: RouteNamesEnum.BUY_SUBSCRIPTION,
            path: RoutePathsEnum.BUY_SUBSCRIPTION,
            component: () => import(/* webpackChunkName: "buy-subscription-page" */ "src/pages/BuySubscriptionPage.vue"),
            meta: {
              requiresAuth: true,
              hideFooter: true,
            }
          },
          {
            name: RouteNamesEnum.BUY_TOKENS,
            path: RoutePathsEnum.BUY_TOKENS,
            component: () => import(/* webpackChunkName: "buy-tokens-page" */ "src/pages/BuyTokensPage.vue"),
            meta: {
              requiresAuth: true,
              hideFooter: true,
            }
          },
          {
            name: RouteNamesEnum.PROFILE,
            path: RoutePathsEnum.PROFILE,
            component: () => import(/* webpackChunkName: "profile-page" */ "src/pages/ProfilePage.vue"),
            meta: {
              requiresAuth: true,
              hideFooter: true,
            }
          },
          {
            name: RouteNamesEnum.GENERATE_IMAGE,
            path: `${RoutePathsEnum.GENERATE}/${RoutePathsEnum.CHARACTER_ID}?`,
            props: true,
            component: () => import(/* webpackChunkName: "generate-page" */ "src/pages/GenerateImagePage.vue"),
            meta: {
              hideFooter: true,
            }
          },
          {
            name: RouteNamesEnum.CHARACTER_PROFILE,
            path: `${RoutePathsEnum.PROFILE}/${RoutePathsEnum.CHARACTER_ID}`,
            props: true,
            component: () => import(/* webpackChunkName: "character-profile-page" */ "src/pages/CharacterProfilePage.vue"),
            meta: {
              hideFooter: true,
            }
          },
          {
            name: RouteNamesEnum.CREATE_MY_AI,
            path: RoutePathsEnum.CREATE_MY_AI,
            component: () => import(/* webpackChunkName: "create-my-ai-page" */ "src/pages/CreateMyAiPage.vue"),
            meta: {
              hideFooter: true,
            }
          },
          {
            name: RouteNamesEnum.CREATE_MY_AI_RESULT,
            path: `${RoutePathsEnum.CREATE_MY_AI_RESULT}/${RoutePathsEnum.CHARACTER_ID}`,
            props: true,
            component: () => import(/* webpackChunkName: "create-my-ai-result-page" */ "src/pages/CreateMyAiResultPage.vue"),
            meta: {
              hideFooter: true,
            }
          },
          {
            path: RoutePathsEnum.MY_AI,
            component: () => import(/* webpackChunkName: "my-ai-layout" */ "src/layouts/MyAiLayout.vue"),
            children: [
              {
                name: RouteNamesEnum.GENERATED_AI,
                path: RoutePathsEnum.APP_EMPTY,
                component: () => import(/* webpackChunkName: "my-generated-ai-page" */ "src/pages/MyGeneratedAiPage.vue"),
                meta: {
                  hideFooter: true,
                  requiresAuth: true,
                }
              },
              {
                name: RouteNamesEnum.LIKED_AI,
                path: RoutePathsEnum.LIKED,
                component: () => import(/* webpackChunkName: "my-liked-ai-page" */ "src/pages/MyLikedAiPage.vue"),
                meta: {
                  hideFooter: true,
                  requiresAuth: true,
                }
              },
              {
                name: RouteNamesEnum.GALLERY_AI,
                path: RoutePathsEnum.GALLERY,
                component: () => import(/* webpackChunkName: "my-gallery-ai-page" */ "src/pages/MyGalleryAiPage.vue"),
                meta: {
                  hideFooter: true,
                  requiresAuth: true,
                }
              },
            ]
          },
          {
            name: RouteNamesEnum.AFFILIATE,
            path: RoutePathsEnum.AFFILIATE,
            component: () => import(/* webpackChunkName: "affiliate-page" */ "src/pages/AffiliatePage.vue"),
          },
          {
            name: RouteNamesEnum.COMMUNITY,
            path: RoutePathsEnum.COMMUNITY,
            component: () => import(/* webpackChunkName: "community-page" */ "src/pages/CommunityPage.vue"),
          },
          {
            name: RouteNamesEnum.LANDING,
            path: `${RoutePathsEnum.KEY_WORD}`,
            props: true,
            component: () => import(/* webpackChunkName: "landing-page" */ "pages/LandingPage.vue"),
            beforeEnter: LandingGuard,
          },
          {
            name: RouteNamesEnum.ABOUT_US,
            path: RoutePathsEnum.ABOUT_US,
            component: () => import(/* webpackChunkName: "about-page" */ "pages/AboutUsPage.vue"),
          },
          {
            name: RouteNamesEnum.LEGAL,
            path: RoutePathsEnum.LEGAL,
            component: () => import(/* webpackChunkName: "legal-page" */ "pages/LegalInformationPage.vue"),
          },
          {
            name: RouteNamesEnum.TERMS,
            path: RoutePathsEnum.TERMS,
            component: () => import(/* webpackChunkName: "terms-page" */ "pages/TermsOfServicePage.vue"),
            meta: {
              hide18PlusDialog: true,
            }
          },
          {
            name: RouteNamesEnum.POLICY,
            path: RoutePathsEnum.POLICY,
            component: () => import(/* webpackChunkName: "policy-page" */ "pages/PrivacyPolicyPage.vue"),
            meta: {
              hide18PlusDialog: true,
            }
          },
          {
            name: RouteNamesEnum.COOKIES,
            path: RoutePathsEnum.COOKIES,
            component: () => import(/* webpackChunkName: "cookies-page" */ "pages/CookiesPolicyPage.vue"),
            meta: {
              hide18PlusDialog: true,
            }
          },
          {
            name: RouteNamesEnum.CONTENT_REMOVAL,
            path: RoutePathsEnum.CONTENT_REMOVAL,
            component: () => import(/* webpackChunkName: "content-removal-page" */ "pages/ContentRemovalPolicyPage.vue"),
          },
          {
            name: RouteNamesEnum.BLOCKED_CONTENT,
            path: RoutePathsEnum.BLOCKED_CONTENT,
            component: () => import(/* webpackChunkName: "blocked-content-page" */ "pages/BlockedContentPolicyPage.vue"),
          },
          {
            name: RouteNamesEnum.EXEMPTION,
            path: RoutePathsEnum.EXEMPTION,
            component: () => import(/* webpackChunkName: "exemption-page" */ "pages/Exemption18Page.vue"),
          },
          {
            name: RouteNamesEnum.DMCA,
            path: RoutePathsEnum.DMCA,
            component: () => import(/* webpackChunkName: "dmca-page" */ "pages/DMCAPolicyPage.vue"),
          },
          {
            name: RouteNamesEnum.COMPLAINT,
            path: RoutePathsEnum.COMPLAINT,
            component: () => import(/* webpackChunkName: "complaint-page" */ "pages/ComplaintPolicy.vue"),
          },
          {
            name: RouteNamesEnum.SITEMAP,
            path: RoutePathsEnum.SITEMAP,
            component: () => import(/* webpackChunkName: "sitemap-page" */ "pages/SitemapPage.vue"),
          },
          {
            path: RoutePathsEnum.CHAT,
            component: () => import( /* webpackChunkName: "chat-layout" */ "src/layouts/ChatLayout.vue"),
            children: [
              {
                name: RouteNamesEnum.NO_CHAT_SELECTED,
                path: RoutePathsEnum.APP_EMPTY,
                component: () => import(/* webpackChunkName: "no-chat-selected-page" */ "src/pages/NoChatSelectedPage.vue"),
                meta: {
                  hideFooter: true,
                }
              },
              {
                name: RouteNamesEnum.CHAT_DETAILS,
                path: RoutePathsEnum.CHARACTER_ID,
                props: true,
                component: () => import(/* webpackChunkName: "chat-page" */ "src/pages/ChatPage.vue"),
                beforeEnter: ChatDetailsGuard,
                meta: {
                  hideFooter: true,
                  forceRedirectToHome: true,
                }
              },
            ]
          },
        ]
      },
      {
        name: RouteNamesEnum.OAUTH_STATS,
        path: RoutePathsEnum.OAUTH_STATS,
        component: () => import(/* webpackChunkName: "oauth-stats-page" */ "src/pages/OAuthStatsPage.vue"),
      },
      {
        name: RouteNamesEnum.SIGN_IN,
        path: RoutePathsEnum.SIGN_IN,
        component: () => import(/* webpackChunkName: "sign-in-page" */ "src/pages/SignInPage.vue"),
        beforeEnter: AuthGuard,
      },
      {
        name: RouteNamesEnum.SIGN_UP,
        path: RoutePathsEnum.SIGN_UP,
        component: () => import(/* webpackChunkName: "sign-up-page" */ "src/pages/SignUpPage.vue"),
        beforeEnter: AuthGuard,
      },
      {
        name: RouteNamesEnum.CHECK_EMAIL,
        path: RoutePathsEnum.CHECK_EMAIL,
        component: () => import(/* webpackChunkName: "check-email-page" */ "src/pages/CheckYourEmail.vue"),
        beforeEnter: AuthGuard,
      },
      {
        name: RouteNamesEnum.FIND_ACC,
        path: RoutePathsEnum.FIND_ACC,
        component: () => import(/* webpackChunkName: "find-acc-page" */ "src/pages/FindAccountPage.vue"),
        beforeEnter: AuthGuard,
      },
      {
        name: RouteNamesEnum.CONFIRM_REGISTRATION,
        path: RoutePathsEnum.CONFIRM_REGISTRATION,
        component: () => import(/* webpackChunkName: "confirm-registration-page" */ "pages/ConfirmRegistrationPage.vue"),
        beforeEnter: AuthGuard,
      },
      {
        name: RouteNamesEnum.RESET_PASSWORD,
        path: RoutePathsEnum.RESET_PASSWORD,
        component: () => import(/* webpackChunkName: "reset-password-page" */ "src/pages/ResetPasswordPage.vue"),
        beforeEnter: AuthGuard,
      },
      {
        name: RouteNamesEnum.MAINTENANCE,
        path: RoutePathsEnum.MAINTENANCE,
        component: () => import(/* webpackChunkName: "maintenance-page" */ "src/pages/MaintenancePage.vue"),
        meta: {
          hide18PlusDialog: true,
        }
      }
    ]
  },
  {
    path: "/oauth2/redirect",
    redirect: RoutePathsEnum.SIGN_IN,
  },
  {
    name: RouteNamesEnum.PAGE_NOT_FOUND,
    path: "/:catchAll(.*)*",
    alias: ["/404"],
    component: () => import(/* webpackChunkName: "404-page" */ "pages/ErrorNotFound.vue"),
  },
];

export default routes;
