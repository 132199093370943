<template>
<q-icon>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8995 9C11.5123 8.99967 10.1678 9.48003 9.0949 10.3593C8.02203 11.2386 7.28696 12.4624 7.01475 13.8226C7.01474 13.8226 7.01477 13.8225 7.01475 13.8226L4.56375 26.0821C4.48084 26.4967 4.5349 26.9271 4.71783 27.3083C4.90077 27.6894 5.20264 28.0008 5.57796 28.1955C5.95327 28.3902 6.3817 28.4577 6.79867 28.3877C7.21565 28.3177 7.59858 28.1141 7.88976 27.8075L13.9123 21.467C14.1955 21.1688 14.5887 21 14.9999 21H23.2499C24.8412 21 26.3673 20.3679 27.4925 19.2426C28.6177 18.1174 29.2499 16.5913 29.2499 15C29.2499 13.4087 28.6177 11.8826 27.4925 10.7574C26.3673 9.63214 24.8412 9 23.2499 9H12.8995ZM12.8999 6C10.8192 5.99959 8.80261 6.72012 7.19331 8.039C5.58392 9.35796 4.48127 11.1938 4.07301 13.2342L1.62199 25.4938C1.62198 25.4939 1.62199 25.4938 1.62199 25.4938C1.41153 26.5463 1.54881 27.6386 2.01317 28.6062C2.47753 29.5739 3.24382 30.3643 4.19655 30.8586C5.14927 31.3528 6.23682 31.5239 7.2953 31.3463C8.35376 31.1686 9.3258 30.6517 10.0649 29.8735C10.0649 29.8735 10.0649 29.8735 10.0649 29.8735L15.6439 24H23.2499C25.6368 24 27.926 23.0518 29.6138 21.364C31.3016 19.6761 32.2499 17.3869 32.2499 15C32.2499 12.6131 31.3016 10.3239 29.6138 8.63604C27.926 6.94821 25.6368 6 23.2499 6H12.8999C12.9 6 12.8997 6 12.8999 6Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3063 12.0708C31.1195 11.9127 31.9069 12.4439 32.0649 13.2571L34.465 25.6051C34.6691 26.6562 34.527 27.7454 34.0597 28.7089C33.5925 29.6724 32.8254 30.4585 31.8736 30.949C30.9218 31.4396 29.8365 31.6083 28.7807 31.4298C27.7249 31.2513 26.7554 30.7353 26.0177 29.9591L19.9125 23.5329C19.3419 22.9323 19.3663 21.9829 19.9669 21.4123C20.5674 20.8417 21.5169 20.866 22.0875 21.4666L28.1923 27.8924C28.1923 27.8924 28.1924 27.8925 28.1923 27.8924C28.4829 28.1981 28.8649 28.4015 29.2808 28.4718C29.6967 28.5421 30.1242 28.4757 30.4992 28.2824C30.8741 28.0891 31.1763 27.7795 31.3604 27.3999C31.5444 27.0204 31.6004 26.5915 31.5201 26.1774C31.52 26.1774 31.5201 26.1775 31.5201 26.1774L29.1201 13.8294C28.962 13.0162 29.4931 12.2289 30.3063 12.0708Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 12C12.8284 12 13.5 12.6716 13.5 13.5V16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5V13.5C10.5 12.6716 11.1716 12 12 12Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 15C9 14.1716 9.67157 13.5 10.5 13.5H13.5C14.3284 13.5 15 14.1716 15 15C15 15.8284 14.3284 16.5 13.5 16.5H10.5C9.67157 16.5 9 15.8284 9 15Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 15C19.5 14.1716 20.1716 13.5 21 13.5H24C24.8284 13.5 25.5 14.1716 25.5 15C25.5 15.8284 24.8284 16.5 24 16.5H21C20.1716 16.5 19.5 15.8284 19.5 15Z" fill="#D9452F"/>
  </svg>
</q-icon>
</template>
