import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { RouteNamesEnum } from "src/utils/enums/general/RouteNamesEnum";
import { useAppLink } from "src/composable/general/useAppLink";
import { useAuthStore } from "stores/auth";
import { useUuid } from "src/composable/general/useUuid";
import { useMutation } from "@tanstack/vue-query";
import { createTemporary } from "src/api/ChatPublicApi";
import { Dialog } from "quasar";
import OperationResult from "components/common/OperationResult.vue";
import { OperationResultsEnum } from "src/utils/enums/general/OperationResultsEnum";
import { ExceptionsEnum } from "src/utils/enums/general/ExceptionsEnum";
import { useUserStore } from "stores/user";
import { GenderEnum } from "src/utils/enums/user/GenderEnum";

export const ChatDetailsGuard = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  if (!userStore.getAdvantage) {
    userStore.setAdvantage(GenderEnum.FEMALE);
  }
  if (!authStore.getIsAuthorized) {
    const temporaryId = localStorage.getItem("temporaryId");
    if(!temporaryId) {
      const {generateAndSetToLS} = useUuid();
      const temporaryId = generateAndSetToLS("temporaryId");
      const {mutateAsync} = useMutation({
        mutationFn: createTemporary,
      });
      try {
        await mutateAsync(temporaryId);
        next();
      } catch (e) {
        Dialog.create({
          component: OperationResult,
          componentProps: {
            result: OperationResultsEnum.FAILED,
            message: ExceptionsEnum.UNKNOWN_ERROR_OCCURRED,
          }
        });
        const {homeLink} = useAppLink();
        next(homeLink());
      }
      next();
    } else {
      next();
    }
  } else {
    // if (_from.name === RouteNamesEnum.SIGN_IN) {
    //   const {homeLink} = useAppLink();
    //   next(homeLink());
    // } else {
    //   next();
    // }
    next();
  }
};
