import { defineStore } from "pinia"
import {IWallet} from "src/types/wallet/IWallet";

export const useWalletStore = defineStore({
  id: "wallet",
  state: () => ({
    isWalletLoading: false,
    wallet: null as null | IWallet,
  }),
  getters: {
    getIsWalletLoading: (state): boolean => state.isWalletLoading,
    getBalance: (state): number => state.wallet?.balance || 0,
    getIsNoFunds(): boolean {
      return this.getBalance === 0;
    }
  },
  actions: {
    setIsWalletLoading(val: boolean) {
      this.isWalletLoading = val;
    },
    setWallet(val: IWallet) {
      this.wallet = val;
    },
  },
});
