import {api} from "boot/axios";
import {IPageableResponse} from "src/types/util/IPageableResponse";
import {IConversation} from "src/types/chat/IConversation";
import {IChatMessage} from "src/types/chat/IChatMessage";
import {ISendMessageRequest} from "src/types/chat/ISendMessageRequest";

export const getPublicChats = async (page: number, temporaryId: string) => {
  return (await api.get<IPageableResponse<IConversation>>("/public/chat/get_chats", {
    params: {
      page,
    },
    headers: {
      "temporary-id": temporaryId,
    }
  })).data;
}

export const getPublicChat = async (chatId: string, temporaryId: string) => {
  return (await api.get<IConversation>("/public/chat/get_chat_by_id", {
    params: {
      chatId,
    },
    headers: {
      "temporary-id": temporaryId,
    }
  })).data;
}

export const getPublicMessages = async (chatId: string, page: number, temporaryId: string) => {
  return (await api.get<IPageableResponse<IChatMessage>>("/public/chat/get_chat_messages", {
    params: {
      chatId,
      page,
    },
    headers: {
      "temporary-id": temporaryId,
    }
  })).data;
}

export const sendPublicMessage = async (payload: ISendMessageRequest) => {
  return (await api.post<IConversation>("/public/chat/send", payload, {
    headers: {
      "temporary-id": payload.temporaryId,
    }
  })).data;
}

export const createTemporary = async (temporaryId: string) => {
  return (await api.post<unknown>("/public/chat/create_temporary", null, {
    headers: {
      "temporary-id": temporaryId,
    }
  })).data;
}

// export const unBlurMessage = async (messageId: string) => {
//   return (await api.post<IChatMessage>("/chat/un_blur_image", null, {
//     params: {messageId}
//   })).data;
// }
