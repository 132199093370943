export enum RouteNamesEnum {

  SIGN_IN = "SignIn",
  OAUTH_STATS = "OAuth",
  SIGN_UP = "SignUp",
  CHECK_EMAIL = "CheckEmail",
  FIND_ACC = "FindAcc",
  CONFIRM_REGISTRATION = "ConfirmRegistration",
  RESET_PASSWORD = "ResetPassword",
  HOME = "Home",
  BUY_SUBSCRIPTION = "BuySubscription",
  BUY_TOKENS = "BuyTokens",
  CHAT_DETAILS = "ChatDetails",
  PROFILE = "Profile",
  CHARACTER_PROFILE = "CharacterProfile",
  GENERATE_IMAGE = "GenerateImage",
  CREATE_MY_AI = "CreateMyAi",
  CREATE_MY_AI_RESULT = "CreateMyAiResult",
  AFFILIATE = "Affiliate",
  COMMUNITY = "Community",
  LANDING = "Landing",
  ABOUT_US = "AboutUs",
  LEGAL = "LegalInfo",
  TERMS = "TermsOfService",
  POLICY = "PrivacyPolicy",
  COOKIES = "CookiesPolicy",
  CONTENT_REMOVAL = "ContentRemoval",
  BLOCKED_CONTENT = "BlockedContent",
  EXEMPTION = "Exemption",
  DMCA = "DmcaPolicy",
  COMPLAINT = "Complaint",
  SITEMAP = "Sitemap",
  NO_CHAT_SELECTED = "NoChatSelected",
  GENERATED_AI = "GeneratedAI",
  LIKED_AI = "LikedAI",
  GALLERY_AI = "GalleryAI",
  MAINTENANCE = "Maintenance",
  PAGE_NOT_FOUND = "PageNotFound"
}
