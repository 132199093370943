export enum RoutePathsEnum {
  APP_ROOT = "/",
  APP_EMPTY = "",
  SIGN_IN = "sign-in",
  OAUTH_STATS = "first-oauth-redirect",
  SIGN_UP = "sign-up",
  CHECK_EMAIL = "check-email",
  FIND_ACC = "find-acc",
  CONFIRM_REGISTRATION = "confirm_registration",
  CONFIRM_LOGIN = "confirm-login",
  RESET_PASSWORD = "confirm_restore_password",
  BUY_SUBSCRIPTION = "buy-subscription",
  BUY_TOKENS = "buy-tokens",
  CHAT = "chat",
  PROFILE = "profile",
  GENERATE = "generate",
  CREATE_MY_AI = "create-my-ai",
  CREATE_MY_AI_RESULT = "create-my-ai-result",
  MY_AI = "my-ai",
  AFFILIATE = "affiliate",
  COMMUNITY = "community",
  ABOUT_US = "about-us",
  LEGAL = "legal-info",
  TERMS = "terms-service",
  POLICY = "privacy-policy",
  COOKIES = "cookies-policy",
  CONTENT_REMOVAL = "content-removal",
  BLOCKED_CONTENT = "blocked-content",
  EXEMPTION = "exemption",
  DMCA = "dmca",
  COMPLAINT = "complaint",
  SITEMAP = "sitemap",
  LIKED = "liked",
  GALLERY = "gallery",
  MAINTENANCE = "maintenance",
  NOT_FOUND = "404",

  LANGUAGE_LAYOUT = ":lang?",
  CHARACTER_ID = ":characterId",
  KEY_WORD = ":keyWord",
}
