import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import {useAuthStore} from "stores/auth";
import {useAppLink} from "src/composable/general/useAppLink";

export const AuthGuard = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const auth= useAuthStore();
  const {homeLink} = useAppLink();
  if (auth.getIsAuthorized) {
    next(homeLink());
  } else {
    next();
  }
};
