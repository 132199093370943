<template>
<q-icon>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3934 7.3934C10.2064 4.58035 14.0218 3 18 3C21.9782 3 25.7936 4.58035 28.6066 7.3934C31.4196 10.2064 33 14.0218 33 18C33 19.9698 32.612 21.9204 31.8582 23.7403C31.1044 25.5601 29.9995 27.2137 28.6066 28.6066C27.2137 29.9995 25.5601 31.1044 23.7403 31.8582C21.9204 32.612 19.9698 33 18 33C16.0302 33 14.0796 32.612 12.2597 31.8582C10.4399 31.1044 8.78628 29.9995 7.3934 28.6066C6.00052 27.2137 4.89563 25.5601 4.14181 23.7403C3.38799 21.9204 3 19.9698 3 18C3 14.0218 4.58035 10.2064 7.3934 7.3934ZM18 6C14.8174 6 11.7652 7.26428 9.51472 9.51472C7.26428 11.7652 6 14.8174 6 18C6 19.5759 6.31039 21.1363 6.91345 22.5922C7.5165 24.0481 8.40041 25.371 9.51472 26.4853C10.629 27.5996 11.9519 28.4835 13.4078 29.0866C14.8637 29.6896 16.4241 30 18 30C19.5759 30 21.1363 29.6896 22.5922 29.0866C24.0481 28.4835 25.371 27.5996 26.4853 26.4853C27.5996 25.371 28.4835 24.0481 29.0866 22.5922C29.6896 21.1363 30 19.5759 30 18C30 14.8174 28.7357 11.7652 26.4853 9.51472C24.2348 7.26428 21.1826 6 18 6Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.90039 13.5C3.90039 12.6716 4.57196 12 5.40039 12H30.6004C31.4288 12 32.1004 12.6716 32.1004 13.5C32.1004 14.3284 31.4288 15 30.6004 15H5.40039C4.57196 15 3.90039 14.3284 3.90039 13.5Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.90039 22.5C3.90039 21.6716 4.57196 21 5.40039 21H30.6004C31.4288 21 32.1004 21.6716 32.1004 22.5C32.1004 23.3284 31.4288 24 30.6004 24H5.40039C4.57196 24 3.90039 23.3284 3.90039 22.5Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0446 3.22765C18.7474 3.66623 18.9616 4.59151 18.523 5.29432C16.1447 9.10554 14.8838 13.5078 14.8838 18.0002C14.8838 22.4926 16.1447 26.8949 18.523 30.7061C18.9616 31.4089 18.7474 32.3342 18.0446 32.7728C17.3418 33.2113 16.4165 32.9971 15.9779 32.2943C13.3023 28.0067 11.8838 23.0542 11.8838 18.0002C11.8838 12.9462 13.3023 7.9937 15.9779 3.70608C16.4165 3.00328 17.3418 2.78908 18.0446 3.22765Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9561 3.22765C18.6589 2.78908 19.5842 3.00328 20.0227 3.70608C22.6984 7.9937 24.1169 12.9462 24.1169 18.0002C24.1169 23.0542 22.6984 28.0067 20.0227 32.2943C19.5842 32.9971 18.6589 33.2113 17.9561 32.7728C17.2533 32.3342 17.0391 31.4089 17.4777 30.7061C19.856 26.8949 21.1169 22.4926 21.1169 18.0002C21.1169 13.5078 19.856 9.10554 17.4777 5.29432C17.0391 4.59151 17.2533 3.66623 17.9561 3.22765Z" fill="#D9452F"/>
  </svg>
</q-icon>
</template>
