import { AxiosError } from "axios";
import { ExceptionsEnum } from "src/utils/enums/general/ExceptionsEnum";
import { ICreateCharacterScheme } from "src/types/scheme/ICreateCharacterScheme";
import { ISummaryScheme } from "src/types/scheme/ISummaryScheme";
import { ICharacterInfoScheme } from "src/types/scheme/ICharacterInfoScheme";
import PersonalitySvg from "components/icons/PersonalitySvg.vue";
import OccupationSvg from "components/icons/OccupationSvg.vue";
import HobbiesSvg from "components/icons/HobbiesSvg.vue";
import HeartSvg from "components/icons/HeartSvg.vue";
import StyleSvg from "components/icons/StyleSvg.vue";
import EthnicitySvg from "components/icons/EthnicitySvg.vue";
import AgeSvg from "components/icons/AgeSvg.vue";
import HairStyleSvg from "components/icons/HairStyleSvg.vue";
import HairColorSvg from "components/icons/HairColorSvg.vue";
import BodySvg from "components/icons/BodySvg.vue";
import BreastSizeSvg from "components/icons/BreastSizeSvg.vue";
import ButtSizeSvg from "components/icons/ButtSizeSvg.vue";
import VoiceSvg from "components/icons/VoiceSvg.vue";
import ClothesSvg from "components/icons/ClothesSvg.vue";
import { IFilterScheme } from 'src/types/scheme/IFilterScheme';
import { SourceEnum } from 'src/utils/enums/character/SourceEnum';

export const veProgressConfig = {
  color: "#D9452F",
  angle: -90,
  size: 172,
  thickness: 12,
  "font-size": "24px",
  "font-color": "#D9452F",
  "empty-thickness": 12,
  animation: "default 0 0"
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const codesExcludedFromTranslation = [7001];

export const formatException = (e: Error) => {
  console.log(e);
  if (e instanceof AxiosError) {
    if (e.code === "ERR_NETWORK") {
      return ExceptionsEnum.SERVER_TECHNICAL_WORKS;
    }
    const status = e.response?.status;
    if (status === 400) {
      const code = e.response?.data?.code || ExceptionsEnum.UNKNOWN_ERROR_OCCURRED;
      return codesExcludedFromTranslation.includes(code) ? e.response?.data?.message : code;
    }
    if (status === 413) {
      return ExceptionsEnum.FILE_TOO_LARGE;
    }
    else if (status === 500) {
      return ExceptionsEnum.INTERNAL_SERVER_ERROR;
    }
    else {
      return ExceptionsEnum.UNKNOWN_ERROR_OCCURRED;
    }
  } else {
    return e.message;
  }
}

export const extractErrorCode = (e: Error): number => {
  if ((e instanceof AxiosError)) {
    return e.response?.data?.code || 0
  }
  return 0;
}

export const formatChatSentTime = (time: string | number) => {
  const date1 = new Date(time);
  const date2 = new Date(Date.now());
  const diffTime: number = Math.abs(date2.getTime() - date1.getTime());
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  if (diffHours <= 23) {
    return date1.toLocaleString("en-IE", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  }
  if (diffHours > 23 && diffHours < 48) {
    return days[date1.getDay()].substring(0, 3);
  }
  if (diffHours >= 48) {
    return date1.toLocaleDateString("en-US");
  }
};

export const formatMessageSentTime = (time: string | number) => {
  const date1 = new Date(time);
  const date2 = new Date(Date.now());
  const diffTime: number = Math.abs(date2.getTime() - date1.getTime());
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  if (diffHours <= 23) {
    return date1.toLocaleString("en-IE", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  } else {
    return Intl.DateTimeFormat("uk-UA", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).format(date1)
  }
};

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const safeRequire = (payload: {
  path: string,
  safePath: string,
  pathExtension?: string;
  safePathExtension?: string;
}) => {
  try {
    try {
      switch (payload.pathExtension) {
        case "svg": return require(`src/${payload.path}.svg`);
        default: return require(`src/${payload.path}.png`);
      }
    } catch(e) {
      switch (payload.safePathExtension) {
        case "svg": return require(`src/${payload.safePath}.svg`);
        default: return require(`src/${payload.safePath}.png`);
      }
    }
  } catch {
    return require("src/assets/img/error-girl-1.png");
  }
}

export const stepsScheme: ICreateCharacterScheme[] = [
  {
    name: "Style",
    groups: [
      {
        name: "Character Style",
        sectionTitle: "Choose style",
        imageCatalog: "style",
        availableChoices: 1,
        size: "lg",
        characterStyleSetter: true
      }
    ],
  },
  {
    name: "Ethnicity, Age",
    groups: [
      {
        name: "Ethnicity",
        sectionTitle: "Choose ethnicity",
        imageCatalog: "ethnicity",
        availableChoices: 1,
        size: "sm",
      },
      {
        name: "Age",
        sectionTitle: "Choose age",
        imageCatalog: "age",
        availableChoices: 1,
        size: "sm",
      }
    ],
  },
  {
    name: "Hair",
    groups: [
      {
        name: "Hair Style",
        sectionTitle: "Choose hair style",
        imageCatalog: "hairStyle",
        availableChoices: 1,
        size: "sm",
      },
      {
        name: "Hair Color",
        sectionTitle: "Choose hair color",
        imageCatalog: "hairColor",
        availableChoices: 1,
        size: "sm",
        isHairColor: true,
      }
    ],
  },
  {
    name: "Body",
    groups: [
      {
        name: "Body Type",
        sectionTitle: "Choose body type",
        imageCatalog: "bodyType",
        availableChoices: 1,
        size: "sm",
      },
      {
        name: "Breast",
        sectionTitle: "Choose breast size",
        imageCatalog: "breastSize",
        availableChoices: 1,
        size: "sm",
      },
      {
        name: "Butt",
        sectionTitle: "Choose butt size",
        imageCatalog: "buttSize",
        availableChoices: 1,
        size: "sm",
      }
    ],
  },
  {
    name: "Personality",
    groups: [
      {
        name: "Personality",
        sectionTitle: "Choose personality",
        imageCatalog: "personality",
        availableChoices: 1,
        isPersonality: true,
      },
      {
        name: "Voice",
        sectionTitle: "Choose voice",
        availableChoices: 1,
        size: "sm",
        isSmall: true,
      },
    ],
  },
  {
    name: "Interests",
    groups: [
      {
        name: "Occupation",
        sectionTitle: "Choose occupation",
        availableChoices: 1,
        size: "sm",
        isSmall: true
      },
      {
        name: "Interests",
        sectionTitle: "Choose interests",
        availableChoices: 3,
        size: "sm",
        isSmall: true,
        isInterests: true
      },
    ],
  },
  {
    name: "Sex drive",
    groups: [
      {
        name: "Sex Drive",
        sectionTitle: "Choose sex drive",
        imageCatalog: "sexDrive",
        availableChoices: 1,
        size: "sm",
        isSexDrive: true
      },
    ],
  },
  {
    name: "Clothes",
    groups: [
      {
        name: "Clothes",
        sectionTitle: "Choose clothing",
        availableChoices: 1,
        size: "sm",
        isSmall: true
      },
    ],
  },
]

export const summaryScheme: ISummaryScheme[] = [
  {
    title: "Style",
    group: "Character Style",
    imageCatalog: "style",
    withTitle: true,
  },
  {
    title: "Ethnicity",
    group: "Ethnicity",
    imageCatalog: "ethnicity",
    withTitle: true,
  },
  {
    title: "Age",
    group: "Age",
    imageCatalog: "age",
    withTitle: true,
  },
  {
    title: "Hair style",
    group: "Hair Style",
    imageCatalog: "hairStyle",
    withTitle: true,
  },
  {
    title: "Hair color",
    group: "Hair Color",
    imageCatalog: "hairColor",
    withTitle: true,
  },
  {
    title: "Body",
    group: "Body Type",
    imageCatalog: "bodyType",
    withTitle: true,
  },
  {
    title: "Breast",
    group: "Breast",
    imageCatalog: "breastSize",
    withTitle: true,
  },
  {
    title: "Butt",
    group: "Butt",
    imageCatalog: "buttSize",
    withTitle: true,
  },
  {
    title: "Personality",
    group: "Personality",
    imageCatalog: "personality",
    withTitle: false,
    isIcon: true,
  },
  {
    title: "Voice",
    group: "Voice",
    withTitle: true,
  },
  {
    title: "Sex Drive",
    group: "Sex Drive",
    withTitle: true,
    imageCatalog: "sexDrive"
  },
  {
    title: "Interests",
    group: "Interests",
    minimalistic: true,
  },
  {
    title: "Occupation",
    group: "Occupation",
    minimalistic: true,
  },
  {
    title: "Clothes",
    group: "Clothes",
    minimalistic: true,
  }
]

export const characterInfoScheme: ICharacterInfoScheme[] = [
  {
    sectionTitle: "Personality attributes",
    items: [
      {
        icon: PersonalitySvg,
        label: "Personality",
        value: "Personality"
      },
      {
        icon: OccupationSvg,
        label: "Occupation",
        value: "Occupation"
      },
      {
        icon: HobbiesSvg,
        label: "Hobbies",
        value: "Interests"
      },
      {
        icon: HeartSvg,
        label: "Sex Drive",
        value: "Sex Drive"
      },
    ]
  },
  {
    sectionTitle: "Physical Attributes",
    items: [
      {
        icon: StyleSvg,
        label: "Style",
        value: "Character Style"
      },
      {
        icon: EthnicitySvg,
        label: "Ethnicity",
        value: "Ethnicity"
      },
      {
        icon: AgeSvg,
        label: "Age",
        value: "Age"
      },
      {
        icon: HairStyleSvg,
        label: "Hair Style",
        value: "Hair Style"
      },
      {
        icon: HairColorSvg,
        label: "Hair Color",
        value: "Hair Color"
      },
      {
        icon: BodySvg,
        label: "Body",
        value: "Body Type"
      },
      {
        icon: BreastSizeSvg,
        label: "Breast",
        value: "Breast"
      },
      {
        icon: ButtSizeSvg,
        label: "Butt",
        value: "Butt"
      },
      {
        icon: VoiceSvg,
        label: "Voice",
        value: "Voice"
      },
      {
        icon: ClothesSvg,
        hidden: true,
        label: "Clothes",
        value: "Clothes"
      },
    ]
  }
]

export const soonTenantsNames = [""];
export const hiddenTenantNames = ["Manga"];

export const personalityDescriptions = [
  {
    key: "Nympho",
    value: "Insatiable, passionate, and constantly craving intimacy.",
  },
  {
    key: "Lover",
    value: "Romantic, affectionate, and cherishes deep emotional.",
  },
  {
    key: "Caregiver",
    value: "Curious, willing, and always eager to try new things.",
  },
  {
    key: "Innocent",
    value: "Optimistic, naive, and sees world with wonder.",
  },
  {
    key: "Jester",
    value: "Playful, humorous, and always there to make you laugh.",
  },
  {
    key: "Confident",
    value: "Trustworthy, a good listener, and always can offer advice.",
  },
  {
    key: "Sage",
    value: "Wise, reflective, and a source of guidance.",
  },
  {
    key: "Temptress",
    value: "Flirtatious, playful, and always leaving you wanting more.",
  },
  {
    key: "Experimenter",
    value: "Curious, willing, and always eager to try new things.",
  },
  {
    key: "Submissive",
    value: "Obedient, yielding, and happy to follow.",
  },
  {
    key: "Dominant",
    value: "Assertive, controlling, and commanding.",
  },
  {
    key: "Mean",
    value: "Cold, dismissive, and often sarcastic.",
  },
]

export const filterScheme: IFilterScheme[] = [
  {
    title: "Anime",
    group: "Character Style",
    type: "options",
  },
  {
    title: "Ethnicity",
    group: "Ethnicity",
    type: "options",
  },
  {
    title: "Source",
    group: "Source",
    type: "btn-group",
    defaultOptions: [SourceEnum.PRIVATE, SourceEnum.PUBLIC],
  },
  {
    title: "Hair Style",
    group: "Hair Style",
    type: "options",
  },
  {
    title: "Hair Color",
    group: "Hair Color",
    type: "options",
  },
  {
    title: "Age",
    group: "Age",
    type: "range",
  }
]
