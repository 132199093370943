import {
  _RouteRecordBase,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { useAuthStore } from "src/stores/auth";
import { useAppLink } from "src/composable/general/useAppLink";
import { AppLanguages, LanguagesEnum } from "stores/language";


const beforeEachGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.params.lang && AppLanguages.findIndex(i => i.value === to.params.lang as LanguagesEnum) === -1) {
    // localStorage.removeItem("lang");
    next({
      path: "404"
    })
  } else {
    if (
      to.matched.some(
        (record: _RouteRecordBase): unknown => record.meta?.requiresAuth
      )
    ) {
      const { signInLink } = useAppLink();
      const auth = useAuthStore();
      if (auth.getIsAuthorized == null) {
        next();
      } else {
        if (!auth.getIsAuthorized) {
          next({
            path: signInLink(),
          });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
};

export default beforeEachGuard;
