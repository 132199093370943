<template>
<q-icon>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill-unset" d="M7.55775 10.0739C11.2852 5.90244 17.7653 7.73544 20.25 8.99994C21.4927 8.52594 24.8978 7.98219 27.135 9.49869C29.9303 11.3947 33.4523 16.4287 30.1905 23.5379C27.5813 29.2259 20.7675 31.6177 18.282 32.2499C16.4182 31.4602 12.3225 29.8739 7.9545 25.9139C3.9945 22.3229 2.89875 15.2872 7.55775 10.0739Z" stroke="#D9452F" stroke-width="3" stroke-linejoin="round"/>
    <path d="M20.2499 9C18.8069 9.5625 15.9209 10.6875 14.3474 14.0625C12.6554 17.6895 13.9536 20.8125 14.3474 22.5" stroke="#D9452F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.75 3C15.9997 3.12525 17.625 3.375 18 4.875C18.3277 6.18675 17.7502 6.99975 17.625 7.875" stroke="#D9452F" stroke-width="3" stroke-linecap="round"/>
    <path d="M21.3597 8.49681C21.1947 8.49419 21.0322 8.45471 20.8844 8.38125C20.7365 8.30779 20.607 8.2022 20.5052 8.07222C20.4034 7.94224 20.3319 7.79115 20.296 7.62999C20.2601 7.46884 20.2607 7.3017 20.2977 7.14081C20.5002 6.17556 20.9592 4.85781 21.998 4.04406C23.036 3.23106 24.425 3.09981 25.4105 3.13431C25.5756 3.13684 25.738 3.17622 25.8859 3.24959C26.0337 3.32296 26.1633 3.42846 26.2652 3.55837C26.3671 3.68827 26.4386 3.8393 26.4746 4.00042C26.5106 4.16153 26.5101 4.32865 26.4732 4.48956C26.2707 5.45556 25.811 6.77331 24.773 7.58706C23.735 8.40006 22.346 8.53056 21.3597 8.49681Z" stroke="#D9452F" stroke-width="3"/>
  </svg>
</q-icon>
</template>
