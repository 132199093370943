<template>
<q-icon>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.477 19.977C19.7429 18.7112 21.4598 18 23.25 18C24.0784 18 24.75 18.6716 24.75 19.5C24.75 20.3284 24.0784 21 23.25 21C22.2554 21 21.3016 21.3951 20.5984 22.0984C19.8951 22.8016 19.5 23.7554 19.5 24.75V26.25C19.5 27.2446 19.8951 28.1984 20.5984 28.9017C21.3016 29.6049 22.2554 30 23.25 30C24.2446 30 25.1984 29.6049 25.9017 28.9017C26.6049 28.1984 27 27.2446 27 26.25V23.55C27 22.7216 27.6716 22.05 28.5 22.05C29.3284 22.05 30 22.7216 30 23.55V26.25C30 28.0402 29.2888 29.7571 28.023 31.023C26.7571 32.2888 25.0402 33 23.25 33C21.4598 33 19.7429 32.2888 18.477 31.023C17.2112 29.7571 16.5 28.0402 16.5 26.25V24.75C16.5 22.9598 17.2112 21.2429 18.477 19.977Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 19.5C11.25 18.6716 11.9216 18 12.75 18C14.5402 18 16.2571 18.7112 17.523 19.977C18.7888 21.2429 19.5 22.9598 19.5 24.75V26.25C19.5 28.0402 18.7888 29.7571 17.523 31.023C16.2571 32.2888 14.5402 33 12.75 33C10.9598 33 9.2429 32.2888 7.97703 31.023C6.71116 29.7571 6 28.0402 6 26.25V23.55C6 22.7216 6.67157 22.05 7.5 22.05C8.32843 22.05 9 22.7216 9 23.55V26.25C9 27.2446 9.39509 28.1984 10.0984 28.9017C10.8016 29.6049 11.7554 30 12.75 30C13.7446 30 14.6984 29.6049 15.4017 28.9017C16.1049 28.1984 16.5 27.2446 16.5 26.25V24.75C16.5 23.7554 16.1049 22.8016 15.4017 22.0984C14.6984 21.3951 13.7446 21 12.75 21C11.9216 21 11.25 20.3284 11.25 19.5Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 13.5C24 12.6716 24.6716 12 25.5 12H26.25C28.0402 12 29.7571 12.7112 31.023 13.977C32.2888 15.2429 33 16.9598 33 18.75C33 20.5402 32.2888 22.2571 31.023 23.523C29.7571 24.7888 28.0402 25.5 26.25 25.5C25.4216 25.5 24.75 24.8284 24.75 24C24.75 23.1716 25.4216 22.5 26.25 22.5C27.2446 22.5 28.1984 22.1049 28.9017 21.4017C29.6049 20.6984 30 19.7446 30 18.75C30 17.7554 29.6049 16.8016 28.9017 16.0984C28.1984 15.3951 27.2446 15 26.25 15H25.5C24.6716 15 24 14.3284 24 13.5Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.477 4.97703C19.7429 3.71116 21.4598 3 23.25 3C25.0402 3 26.7571 3.71116 28.023 4.97703C29.2888 6.2429 30 7.95979 30 9.75V13.95C30 14.7784 29.3284 15.45 28.5 15.45C27.6716 15.45 27 14.7784 27 13.95V9.75C27 8.75544 26.6049 7.80161 25.9017 7.09835C25.1984 6.39509 24.2446 6 23.25 6C22.2554 6 21.3016 6.39509 20.5984 7.09835C19.8951 7.80161 19.5 8.75544 19.5 9.75C19.5 10.5784 18.8284 11.25 18 11.25C17.1716 11.25 16.5 10.5784 16.5 9.75C16.5 7.95979 17.2112 6.2429 18.477 4.97703Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 15C8.75544 15 7.80161 15.3951 7.09835 16.0984C6.39509 16.8016 6 17.7554 6 18.75C6 19.7446 6.39509 20.6984 7.09835 21.4017C7.80161 22.1049 8.75544 22.5 9.75 22.5C10.5784 22.5 11.25 23.1716 11.25 24C11.25 24.8284 10.5784 25.5 9.75 25.5C7.95979 25.5 6.2429 24.7888 4.97703 23.523C3.71116 22.2571 3 20.5402 3 18.75C3 16.9598 3.71116 15.2429 4.97703 13.977C6.2429 12.7112 7.95979 12 9.75 12H10.5C11.3284 12 12 12.6716 12 13.5C12 14.3284 11.3284 15 10.5 15H9.75Z" fill="#D9452F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 6C11.7554 6 10.8016 6.39509 10.0984 7.09835C9.39509 7.80161 9 8.75544 9 9.75V13.95C9 14.7784 8.32843 15.45 7.5 15.45C6.67157 15.45 6 14.7784 6 13.95V9.75C6 7.95979 6.71116 6.2429 7.97703 4.97703C9.2429 3.71116 10.9598 3 12.75 3C14.5402 3 16.2571 3.71116 17.523 4.97703C18.7888 6.2429 19.5 7.95979 19.5 9.75V24.75C19.5 25.5784 18.8284 26.25 18 26.25C17.1716 26.25 16.5 25.5784 16.5 24.75V9.75C16.5 8.75544 16.1049 7.80161 15.4017 7.09835C14.6984 6.39509 13.7446 6 12.75 6Z" fill="#D9452F"/>
  </svg>
</q-icon>
</template>
