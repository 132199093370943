import { createPinia, defineStore } from "pinia"
import router from "src/router/index";
import {useUserStore} from "stores/user";
import {useAppLink} from "src/composable/general/useAppLink";
import {useWalletStore} from "stores/wallet";
import {useSubscriptionStore} from "stores/subscription";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    isAuthorized: null as null | boolean,
    bearer: null as null | string,
  }),
  getters: {
    getIsAuthorized: (state): boolean | null => state.isAuthorized,
    getBearer: (state): string => `Bearer ${state.bearer}`,
  },
  actions: {
    setBearer() {
      this.bearer = localStorage.getItem("accessToken");
    },
    setInitialAuthorization() {
      this.isAuthorized = !!localStorage.getItem("accessToken");
    },
    setIsAuthorized(token: string, refreshToken?: string) {
      localStorage.setItem("accessToken", token);
      refreshToken && localStorage.setItem("refreshToken", refreshToken);
      this.isAuthorized = true;
    },
    async logOut() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("deviceId");
      localStorage.removeItem("temporaryId");
      this.isAuthorized = false;
      const user = useUserStore();
      const walletStore = useWalletStore();
      const subscriptionStore = useSubscriptionStore();

      user.customReset();
      walletStore.$reset();
      subscriptionStore.$reset();
      const routerInstance = (await router({store: createPinia()}))
      if (routerInstance.currentRoute.value.meta.requiresAuth) {
        const { signInLink } = useAppLink();
        await routerInstance.push(signInLink());
      }
      if (routerInstance.currentRoute.value.meta.forceRedirectToHome) {
        const { homeLink } = useAppLink();
        await routerInstance.push(homeLink());
      }
    },
  },
});
