import { route } from "quasar/wrappers";
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory, Router,
} from "vue-router";
import routes from "./routes";
import beforeEachGuard from "src/router/guards/BeforeEachGuard";
// import * as Sentry from "@sentry/vue";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let routerInstance: Router | null = null;
export default route(function (/* { store, ssrContext } */) {
  if (routerInstance) return routerInstance;
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === "history" ? createWebHistory : createWebHashHistory);

  const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
      if (to.matched.some(record => record.children) && to.meta.keepScrollPosition && from.meta.keepScrollPosition) {
        return {};
      }
      // Otherwise, use the default behavior
      return savedPosition || { top: 0 };
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === "ssr" ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  router.beforeEach(beforeEachGuard);
  routerInstance = router;

  // Sentry.init({
  //   dsn: process.env.SENTRY_DSN,
  //   integrations: [
  //     Sentry.browserTracingIntegration({router}),
  //     Sentry.replayIntegration({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });

  return routerInstance;
});
